
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIEnterDate from "@/views/resources/documentation/element-ui/form/date-picker/EnterDate.vue";
import EUIOtherMeasurements from "@/views/resources/documentation/element-ui/form/date-picker/OtherMeasurements.vue";
import EUIDateRange from "@/views/resources/documentation/element-ui/form/date-picker/DateRange.vue";
import EUIMonthRange from "@/views/resources/documentation/element-ui/form/date-picker/MonthRange.vue";
import EUIDefaultValue from "@/views/resources/documentation/element-ui/form/date-picker/DefaultValue.vue";
import EUIDateFormats from "@/views/resources/documentation/element-ui/form/date-picker/DateFormats.vue";
import EUIDefaultTimeForStartDateAndEndDate from "@/views/resources/documentation/element-ui/form/date-picker/DefaultTimeForStartDateAndEndDate.vue";
import EUILocalization from "@/views/resources/documentation/element-ui/form/date-picker/Localization.vue";

export default defineComponent({
  name: "date-picker",
  components: {
    EUIEnterDate,
    EUIOtherMeasurements,
    EUIDateRange,
    EUIMonthRange,
    EUIDefaultValue,
    EUIDateFormats,
    EUIDefaultTimeForStartDateAndEndDate,
    EUILocalization
  },
  setup() {
    setCurrentPageTitle("DatePicker");
  }
});
